.ServiceMod {
    width: 20%;
    min-width: 250px;
    max-width: 285px;
    height: 415px;
    background-color: #99c4d4;
    display: inline-block;
    vertical-align: top;
    margin: 1%;
    border-radius: 5%;
    color: #666666;
    border: 1px solid #b6a066;
    transition: all .7s linear;
}

.ServiceMod h3 {
    color: white
}

.ServiceMod p {
    padding: 0 5%;
}

.IconImg {
    width: 100px;
    border-radius: 50%;
    border: 1px solid #b6a066;
}

@media (max-width: 499px) {
    .ServiceMod {
        display: block;
        margin: 20px auto;
        margin-left: auto!important
    }

    .IconImg {
        width: 75px;
    }
}