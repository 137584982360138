hr {
    border-color: #99c4d4;
    border-width: 1px;
    border-style: solid;
    margin: 0% 10%;
}
.Test {
    display: block;
    position: relative;
}
.TextOnlyContainer {
    color:#666666;
    height: auto;
    width: 100%;
}

.TextOnlyContainer img {
    position: relative;
    top: 0%;
    left: 50%;
    margin: 5% 0 0 0;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
}

.TextOnlyInner {
    position: relative;
    padding: 5% 15% 1%;
}

@media (max-width: 499px) {
    .TextOnlyContainer img {
        height: 30px;
        width: 30px;
    }

    .Test {
        margin-top: 2%;
    }
}

