body {
  margin: 0;
  font-family: "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}

h1 {
  font-size: 72px;
  font-weight: 300;
}

h2 {
  font-weight: normal;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
}

h3, h4 {
  font-weight: bold;
  font-size: 28px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 499px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  
  h3, h4 {
    font-weight: bold;
    font-size: 18px;
  }
}
