.HeroImage {
    color: white;
    position: relative;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 10px black;
}

.HeroImage img {
    width: 100%;
    height: auto;
}

.HeroText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



