.DesktopOnly {
    display: block;
}

.MobileOnly {
    display: none;
}
@media (max-width: 499px) {
    .SlideItem {
        height: 120px;
        width: 100px;
    } 
    .MobileImage {
        width: 100%;
        display: block;
        height:auto;
    }
    .DesktopOnly {
        display: none;
    }

    .MobileOnly {
        display: block;
    }
}