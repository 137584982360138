.RatesContainer {
    text-align: center;
}

table {
    margin: 50px auto;
    width: 50%;
    text-align: left;
}
th {
    padding: 10px;
    text-align: center;
    background-color: #99c4d4;
    color: white;
    border: 1px solid #666666;
}
td {
    width: 50%;
    padding: 10px;
    border: 1px solid #666666;
}

@media (max-width: 499px) {
    table {
        width: 80%;
    }

    th {
        display: none;
    }

    td {
        display: block;
        width: 100%;
    }

    td:first-child {
        background-color: #99c4d4;
        color: white;
        font-weight: bold;
    }
}