.SliderContainer {
    display: block;
    position: relative;
    height: 100vh;
    width:  100%;
}
.SliderContainer h2 {
    text-shadow: 5px 5px 20px black;
}
.SliderContainer p {
    width: 70%;
    margin: 0 auto;
    font-size: 18px;
    line-height: 140%;
    text-shadow: 5px 5px 20px black;
}

@media (max-width: 499px) {
    .SliderContainer p {
        width: 90%;
        margin: 0 auto;
        font-size: 15px;
        line-height: 125%;
    }
}