.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavItem a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    color: white;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
    color: white;
}

@media (min-width: 500px) {
    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavItem a {
        color:#333;
        font-size: 18px;
        height: 100%;
        padding: 16px 20px;
        border-bottom: 4px solid transparent;
    }
    
    .NavItem a:hover,
    .NavItem a:active,
    .NavItem a.active {
        background-color: #fff;
        color: #b6a066;
        border-bottom: 4px solid #b6a066;
    }
}