.ServiceContainer {
    text-align: center;
}

.ServiceContainer h2 {
    color: #b6a066
}

.BathContainer {
    width: 100%;
    position: relative;
    padding: 1% 15%;
}

.BathIcon {
    display: inline-block;
    vertical-align: top;
    width: 12%;
}

.BathText {
    display: inline-block;
    vertical-align: top;
    width: 50%;

}

.BathText p {
    padding-top: 5%;
}

@media (max-width: 499px) {
    .BathContainer {
        display: block;
        padding: 1% 0;
    }
    .BathText {
        display: block;
        width:75%;
        margin: auto;
    
    }
    .BathText p {
        padding-top: 0;
    }
    .BathIcon {
        display: block;
        width: 50%;
        margin: auto
    }
}
